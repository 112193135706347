import { useState, useEffect } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase"
import api from "../api_ui"

const useUser = () => {
    const [user] = useAuthState(auth)
    const [currentUser, setCurrentUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                try {
                    const { data } = await api.get_user(user.uid)

                    const {
                        id,
                        name,
                        email,
                        daysLeftForPremium,
                        isPremiumExpired
                    } = data

                    setCurrentUser({
                        id,
                        email,
                        displayName: name,
                        photoURL: user.photoURL,
                        daysLeftForPremium,
                        isPremiumExpired
                    })
                } catch (err) {
                    setError(err)
                } finally {
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }
        }

        fetchUserData()
    }, [user])

    return { currentUser, loading, error }
}

export default useUser
