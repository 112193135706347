import api from "../components/api_ui"
import socialBuzzJSON from "../components/static/data/social_buzz.json"
import sentimentJSON from "../components/static/data/sentiment.json"
import socialBuzzChangeJSON from "../components/static/data/social_buzz_change.json"
import sentimentChangeJSON from "../components/static/data/sentiment_change.json"
import wordCloudJSON from "../components/static/data/wordcloud.json"
import onMarketStatusSocialBuzzJSON from "../components/static/data/on_market_status_social_buzz.json"
import onMarketStatusSentimentJSON from "../components/static/data/on_market_status_sentiment.json"
import onMarketStatusSocialBuzzChangeJSON from "../components/static/data/on_market_status_social_buzz_change.json"
import onMarketStatusSentimentChangeJSON from "../components/static/data/on_market_status_sentiment_change.json"
import offMarketStatusSocialBuzzJSON from "../components/static/data/off_market_status_social_buzz.json"
import offMarketStatusSentimentJSON from "../components/static/data/off_market_status_sentiment.json"
import offMarketStatusSocialBuzzChangeJSON from "../components/static/data/off_market_status_social_buzz_change.json"
import offMarketStatusSentimentChangeJSON from "../components/static/data/off_market_status_sentiment_change.json"
import alertsJSON from "../components/static/data/alerts.json"
import pako from "pako"
import moment from "moment-timezone"

const getGraphData = async ({
    graph_type,
    date,
    ticker,
    marketStatusDate,
    marketStatusKey
}) => {
    let response

    // If Market Status with Date
    if (
        date &&
        marketStatusDate &&
        (marketStatusKey === "on" || marketStatusKey === "off")
    ) {
        response = await api.get_market_status_graphs({
            graph_type,
            date,
            marketStatusDate,
            market: marketStatusKey
        })

        try {
            const jsonContent = response.replace(/null/g, "0")

            response = JSON.parse(jsonContent).data
        } catch (e) {
            response = response.data
        }

        return response
    }

    // If Date exists
    else if (date) {
        response = await api.get_graphs(graph_type, date, ticker)

        try {
            if (graph_type === "wordcloud") {
                let wordCloud = {}
                wordCloud[ticker] = response

                return wordCloud
            } else {
                const jsonContent = response.replace(/null/g, "0")

                response = JSON.parse(jsonContent).data
            }
        } catch (e) {
            response = response.data
        }

        return response
    }

    // If Market Status exist
    else if (marketStatusKey) {
        if (marketStatusKey === "on") {
            if (graph_type === "social_buzz")
                return onMarketStatusSocialBuzzJSON
            else if (graph_type === "sentiment")
                return onMarketStatusSentimentJSON
            else if (graph_type === "social_buzz_change")
                return onMarketStatusSocialBuzzChangeJSON
            else if (graph_type === "sentiment_change")
                return onMarketStatusSentimentChangeJSON
        } else if (marketStatusKey === "off") {
            if (graph_type === "social_buzz")
                return offMarketStatusSocialBuzzJSON
            else if (graph_type === "sentiment")
                return offMarketStatusSentimentJSON
            else if (graph_type === "social_buzz_change")
                return offMarketStatusSocialBuzzChangeJSON
            else if (graph_type === "sentiment_change")
                return offMarketStatusSentimentChangeJSON
        }
    }

    if (graph_type === "social_buzz") return socialBuzzJSON
    else if (graph_type === "sentiment") return sentimentJSON
    else if (graph_type === "social_buzz_change") return socialBuzzChangeJSON
    else if (graph_type === "sentiment_change") return sentimentChangeJSON
    else if (graph_type === "wordcloud") return wordCloudJSON
}

const decompressResponse = async (response) => {
    try {
        // Step 1: Base64 decode the gzipped data
        const decodedData = atob(response)

        // Step 2: Convert the Base64 string into a Uint8Array
        const charData = decodedData.split("").map((c) => c.charCodeAt(0))
        const byteArray = new Uint8Array(charData)

        // Step 3: Decompress the gzipped data using pako
        const decompressedData = pako.inflate(byteArray, { to: "string" })

        // Step 4: Parse the decompressed JSON data
        const jsonData = JSON.parse(decompressedData.replaceAll("NaN", null))

        return jsonData
    } catch (error) {
        console.log("Decompression Error: ", error)
    }
}

const getTodayDate = (providedDate, format) => {
    let date = moment().tz("America/New_York")

    if (providedDate) {
        date = moment(providedDate).tz("America/New_York")
    }

    const cutoffTime = date.clone().set({
        hour: 8,
        minute: 30,
        second: 0,
        millisecond: 0
    })

    let assignedDate

    if (date.isBefore(cutoffTime)) {
        // If the current time is before 8:30 AM ET, use the previous day
        assignedDate = date.clone().subtract(1, "day")
    } else {
        // Otherwise, use the current day
        assignedDate = date.clone()
    }

    assignedDate.set({
        hour: 8,
        minute: 30,
        second: 0,
        millisecond: 0
    })

    if (format) {
        return assignedDate.format(format)
    }

    return assignedDate.format("YYYY-MM-DDTHH:mm:ssZ")
}

// Create Mapping for Alerts
const getMappedAlertData = (data, key1, key2) => {
    const stockList = api.get_tickers()

    return stockList.map((stock) => {
        const buzz1 = data.find((buzz) => buzz.fields === key1)
        const buzz2 = data.find((buzz) => buzz.fields === key2)
        if (!buzz1 || !buzz2) return

        if (stock === "FB/META") return (buzz1["META"] + buzz2["META"]) / 2

        return (buzz1[stock] + buzz2[stock]) / 2
    })
}

// Generate Pre-market Alerts
const generatePreMarketAlerts = (apiResponse) => {
    const mappedSocialSentimentChange = getMappedAlertData(
        apiResponse.changeSentiment,
        "one_day_st_change_percent",
        "one_day_news_change_percent"
    )
    // one_day_news_change_percent
    const mappedSocialBuzzChange = getMappedAlertData(
        apiResponse.changeBuzz,
        "one_day_st_change_percent",
        "one_day_news_change_percent"
    )

    const stockList = api.get_tickers()
    const preMarketAlerts = mappedSocialSentimentChange
        .map((sentimentChange, index) => {
            const socialBuzzChange = mappedSocialBuzzChange[index]
            const stock = stockList[index]

            if (sentimentChange >= 25 && socialBuzzChange >= 100)
                return { stock, result: "STRONG_BUY" }
            else if (sentimentChange >= 25 && socialBuzzChange >= 50)
                return { stock, result: "BUY" }
            else if (sentimentChange <= -25 && socialBuzzChange >= 100)
                return { stock, result: "STRONG_SELL" }
            else if (sentimentChange <= -25 && socialBuzzChange >= 50)
                return { stock, result: "SELL" }
        })
        .filter((stock) => stock)

    return preMarketAlerts
}

// Generate Momentum Alerts
const generateMomentumAlerts = (apiResponse) => {
    const mappedPrevDaySentiment = getMappedAlertData(
        apiResponse.sentiment,
        "one_day_st",
        "one_day_news"
    )
    const mappedOneWeekSentiment = getMappedAlertData(
        apiResponse.sentiment,
        "one_week_st",
        "one_week_news"
    )
    const mappedTwoWeekSentiment = getMappedAlertData(
        apiResponse.sentiment,
        "two_week_st",
        "two_week_news"
    )
    const mappedOneMonthSentiment = getMappedAlertData(
        apiResponse.sentiment,
        "one_month_st",
        "one_month_news"
    )

    const stockList = api.get_tickers()
    const momentumAlerts = mappedOneWeekSentiment
        .map((oneWeek, index) => {
            const twoWeek = mappedTwoWeekSentiment[index]
            const oneMonth = mappedOneMonthSentiment[index]
            const prevDay = mappedPrevDaySentiment[index]
            const stock = stockList[index]

            // 1.
            if (oneMonth < twoWeek && twoWeek < oneWeek && oneWeek < prevDay) {
                return {
                    stock,
                    result: "STRONG_BUY"
                }
            }
            // 2.
            else if (
                oneMonth > twoWeek &&
                twoWeek > oneWeek &&
                oneWeek > prevDay
            ) {
                return {
                    stock,
                    result: "STRONG_SELL"
                }
            }
            // 3.
            else if (twoWeek < oneWeek && oneWeek < prevDay) {
                return {
                    stock,
                    result: "BUY"
                }
            }
            // 4.
            else if (twoWeek > oneWeek && oneWeek > prevDay) {
                return {
                    stock,
                    result: "SELL"
                }
            }
        })
        .filter((stock) => stock)

    return momentumAlerts
}

const fetchStoredAlerts = async (date) => {
    return api
        .get_stored_alerts(date)
        .then((response) => {
            const { status, data } = response

            if (status) {
                const { alerts } = data
                return JSON.parse(alerts)
            }

            return
        })
        .catch((error) => {
            console.error(`Error fetching alerts ${error}`)
            return { pre_market_alerts: [], momentum_alerts: [] }
        })
}

const storeAlerts = async (date, alerts) => {
    return api
        .set_alerts({ date, alerts })
        .then((response) => {
            const { status } = response

            if (status) return true
            return false
        })
        .catch((error) => {
            console.error(`Error storing alerts ${error}`)
            return false
        })
}

const generateAlerts = async (date, price_enabled, apiResponse) => {
    try {
        const payloadDate = getTodayDate(date)

        // If No Date and API Response exist and No Price Enabled
        if (!date && price_enabled && !apiResponse) {
            const { preMarketAlerts, momentumAlerts } = alertsJSON

            return {
                preMarketAlerts,
                momentumAlerts
            }
        }

        // If Market Status Response exists
        if (apiResponse) {
            const preMarketAlerts = generatePreMarketAlerts(apiResponse)
            const momentumAlerts = generateMomentumAlerts(apiResponse)

            return {
                preMarketAlerts,
                momentumAlerts
            }
        }

        // Fetch stored alerts ONLY if Price Alerts
        if (price_enabled) {
            const storedAlerts = await fetchStoredAlerts(date)

            if (storedAlerts) {
                const { preMarketAlerts, momentumAlerts } = storedAlerts

                return {
                    preMarketAlerts,
                    momentumAlerts
                }
            }
        }

        const pages = [1, 2, 3]

        // Fetch data from all pages concurrently
        const results = await Promise.all(
            pages.map((page) =>
                api
                    .get_alerts(payloadDate, price_enabled, page)
                    .catch((error) => {
                        console.error(
                            `Error fetching alerts for page ${page}: ${error}`
                        )
                        return { pre_market_alerts: [], momentum_alerts: [] }
                    })
            )
        )

        // Combine the results
        const pre_market_alerts = results.flatMap(
            (result) => result.pre_market_alerts || []
        )
        const momentum_alerts = results.flatMap(
            (result) => result.momentum_alerts || []
        )

        // Store the alerts if Price Alerts
        if (price_enabled) {
            try {
                await api.set_alerts(date, {
                    pre_market_alerts,
                    momentum_alerts
                })
                console.log(`Alerts Stored for ${date}`)
            } catch (error) {
                console.error(`Error storing alerts ${error}`)
            }
        }

        return {
            preMarketAlerts: pre_market_alerts,
            momentumAlerts: momentum_alerts
        }
    } catch (e) {
        console.log("Error in generateAlerts", e)
        return { preMarketAlerts: [], momentumAlerts: [] }
    }
}

// Create functions for Storing and Fetching Alerts Weightage from Localstorage
const storeAlertsWeightage = (alertsWeightage) => {
    localStorage.setItem("alertsWeightage", JSON.stringify(alertsWeightage))
}

const fetchAlertsWeightage = () => {
    const alertsWeightage = localStorage.getItem("alertsWeightage")

    return alertsWeightage ? JSON.parse(alertsWeightage) : null
}

export {
    getGraphData,
    decompressResponse,
    getTodayDate,
    generateAlerts,
    storeAlertsWeightage,
    fetchAlertsWeightage
}
