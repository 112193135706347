import React, { createContext, useState } from "react"

// Create a provider component
const WeightProvider = ({ children }) => {
    const [weights, setWeights] = useState({
        stocktwits: 0,
        news: 70,
        thirdParty: 0,
        gtrends: 0,
        reddit: 30,
        edgar: 0,
        bluesky: 0
    })

    return (
        <WeightContext.Provider value={{ weights, setWeights }}>
            {children}
        </WeightContext.Provider>
    )
}

// Create the context
const WeightContext = createContext()

export { WeightProvider, WeightContext }
