import { Modal, Backdrop } from "@material-ui/core"
import { Slide } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@mui/material/TextField"
import Button from "@material-ui/core/Button"
import { useEffect, useState } from "react"
import { fetchAlertsWeightage, storeAlertsWeightage } from "../utils/functions"

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important"
    },
    icon: {
        fill: "blue"
    },
    modal: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 2),
        paddingRight: 0
    },
    fullscreenBackground: {
        border: "solid 2px red !important",
        height: "100% !important"
    },
    summaryRoot: {
        justifyContent: "flex-start !important"
    },
    summaryContent: {
        display: "flex",
        alignItems: "center",
        flexGrow: "unset !important",
        marginLeft: "10px"
    }
}))

const initialConditions = [
    {
        sentiment_change: 25,
        buzz_change: 100,
        price_change: 2,
        result: "STRONG_BUY"
    },
    {
        sentiment_change: 25,
        buzz_change: 50,
        price_change: 1,
        result: "BUY"
    },
    {
        sentiment_change: -25,
        buzz_change: 100,
        price_change: -2,
        result: "STRONG_SELL"
    },
    {
        sentiment_change: -25,
        buzz_change: 50,
        price_change: -1,
        result: "SELL"
    }
]

const AlertsWeightage = ({ modalToggle, setModalToggle }) => {
    const classes = useStyles()
    const [conditions, setConditions] = useState(initialConditions)

    const handleWeights = (index, field, value = 0) => {
        const newConditions = [...conditions]
        newConditions[index][field] = value
        setConditions(newConditions)
        storeAlertsWeightage(newConditions)
    }

    const handleUpdateAlerts = () => {
        storeAlertsWeightage(conditions)
        setModalToggle(false)
    }

    const resetAlertsWeightage = () => {
        setConditions(initialConditions)
        storeAlertsWeightage(initialConditions)
    }

    useEffect(() => {
        const alertsWeightage = fetchAlertsWeightage()

        if (alertsWeightage) {
            setConditions(alertsWeightage)
        } else {
            storeAlertsWeightage(initialConditions)
        }
    }, [])

    return (
        <div>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                className={classes.modal}
                open={modalToggle}
                keepMounted
                onClose={() => {
                    setModalToggle(false)
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
                style={{
                    width: "50%",
                    marginTop: "0",
                    maxHeight: "100%",
                    overflowY: "scroll"
                }}>
                <Slide
                    direction='up'
                    in={modalToggle}
                    mountOnEnter
                    unmountOnExit
                    style={{ transformOrigin: "0 0 0" }}
                    {...(modalToggle ? { timeout: 300 } : { timeout: 300 })}>
                    <div class='container-fluid' className={classes.paper}>
                        <div className='flex flex-col'>
                            <div className='flex gap-2 items-center'>
                                <h2>Alerts Weightage</h2>
                                <button onClick={resetAlertsWeightage}>
                                    Reset
                                </button>
                            </div>

                            <div className='flex flex-col gap-4'>
                                {conditions.map((condition, index) => {
                                    const {
                                        result,
                                        sentiment_change,
                                        buzz_change,
                                        price_change
                                    } = condition

                                    return (
                                        <div className='flex flex-col gap-2'>
                                            <p className='m-0'>{result}</p>
                                            <div className='flex gap-2'>
                                                <TextField
                                                    variant='outlined'
                                                    size='small'
                                                    label='Sentiment Change'
                                                    type='number'
                                                    max={100}
                                                    min={-100}
                                                    onChange={(event) => {
                                                        handleWeights(
                                                            index,
                                                            "sentiment_change",
                                                            parseInt(
                                                                event.target
                                                                    .value
                                                            )
                                                        )
                                                    }}
                                                    value={sentiment_change}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}></TextField>
                                                <TextField
                                                    variant='outlined'
                                                    size='small'
                                                    label='Buzz Change'
                                                    value={buzz_change}
                                                    type='number'
                                                    max={100}
                                                    min={-100}
                                                    onChange={(event) => {
                                                        handleWeights(
                                                            index,
                                                            "buzz_change",
                                                            parseInt(
                                                                event.target
                                                                    .value
                                                            )
                                                        )
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}></TextField>
                                                <TextField
                                                    variant='outlined'
                                                    size='small'
                                                    label='Price Change'
                                                    value={price_change}
                                                    type='number'
                                                    max={100}
                                                    min={0}
                                                    onChange={(event) => {
                                                        handleWeights(
                                                            index,
                                                            "price_change",
                                                            parseInt(
                                                                event.target
                                                                    .value
                                                            )
                                                        )
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}></TextField>
                                            </div>
                                        </div>
                                    )
                                })}
                                <Button
                                    color='primary'
                                    variant='contained'
                                    className='col-1'
                                    onClick={() => {
                                        handleUpdateAlerts()
                                    }}
                                    style={{
                                        backgroundColor: "#034ea2",
                                        color: "white"
                                    }}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </Slide>
            </Modal>
        </div>
    )
}

export default AlertsWeightage
