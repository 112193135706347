import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
    auth,
    logInWithEmailAndPassword,
    signInWithGoogle,
    sendEmailVerificationFromFirebase
} from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import "./static/Login.css"
import Background from "./static/research-login-background.png"
import googleLogo from "./static/google_logo2.png"
import { Modal, Backdrop } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import {
    Slide,
    Button,
    CssBaseline,
    Box,
    Grid,
    Typography
} from "@mui/material"
import { createTheme, ThemeProvider, styled } from "@mui/material/styles"
import api from "./api_ui"

const GoogleLogo = styled("img")({
    width: "100%",
    height: "100%",
    borderRadius: "50%"
})

const useStyles = makeStyles(() => ({
    modal: {
        margin: "auto"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 2),
        paddingRight: 0,
        margin: 10,
        borderRadius: 10
    }
}))

const Copyright = (props) => {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            {...props}>
            {"Copyright © "}
            <Link color='inherit' href='https://finsoftai.com/'>
                FinSoftAi
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    )
}

const theme = createTheme()

function Login() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [user] = useAuthState(auth)
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [enableButton, setEnableButton] = useState(false)
    const [error, setError] = useState("")
    const classes = useStyles()

    const fetchUser = async () => {
        if (user) {
            // Introduce a small delay to ensure user is saved in DB before fetching
            await new Promise((resolve) => setTimeout(resolve, 3000))

            const { data: fetchedUser } = await api.get_user(user.uid)

            if (!fetchedUser) {
                alert("User not found")
                return
            }

            if (!user.emailVerified) {
                await sendEmailVerificationFromFirebase()
                alert("Verification email sent! Please check your inbox.")
                return
            }

            if (!fetchedUser.isAgreementAccept) {
                setShowModal(true)
                return
            }

            // 1BA. If User Created -> Redirect to User Dashboard
            navigate("/board", { replace: true })
        }
    }

    const onboardUser = async () => {
        const { success, error } = await api.update_user({
            id: user.uid,
            isAgreementAccept: true,
            isVerified: user.emailVerified
        })

        setShowModal(false)

        if (success) {
            navigate("/board", { replace: true })
        }

        if (error) {
            setError(error)
        }
    }

    const handleAcceptCondition = (e) => {
        if (e.target.checked) {
            setEnableButton(true)
        } else {
            setEnableButton(false)
        }
    }

    useEffect(() => {
        fetchUser()
    }, [user])

    return (
        <>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                className={classes.modal}
                open={showModal}
                keepMounted
                onClose={() => {
                    setShowModal(false)
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
                style={{
                    width: "50%",
                    marginTop: "0",
                    maxHeight: "100%",
                    overflowY: "scroll"
                }}>
                <Slide
                    direction='up'
                    in={showModal}
                    mountOnEnter
                    unmountOnExit
                    style={{ transformOrigin: "0 0 0" }}
                    {...(showModal ? { timeout: 300 } : { timeout: 300 })}>
                    <div class='container-fluid' className={classes.paper}>
                        <div className='flex flex-col gap-4'>
                            <div className='flex justify-center'>
                                <h4>
                                    FinSoftAi SSi Research End User License
                                    Agreement
                                </h4>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <p>
                                    By clicking “Agree,” you accept these terms
                                    for using SSi Research:-
                                </p>
                                <ol>
                                    <li>
                                        Free 45-Day License – You may use the
                                        Service for free for 45 days. FinSoftAi
                                        may revoke or terminate this license at
                                        its discretion.-
                                    </li>
                                    <li>
                                        Fair Use – Use the Service legally and
                                        responsibly. No misuse, unauthorized
                                        access, or disruptions.
                                    </li>
                                    <li>
                                        No Guarantees – The Service is provided
                                        "as is." We are not liable for any
                                        decisions or outcomes based on it.
                                    </li>
                                    <li>
                                        Privacy & Data – We collect and use your
                                        data to provide and improve the Service.
                                        We do not sell your data. Your
                                        information may be shared with service
                                        providers only as needed to operate the
                                        Service.
                                    </li>
                                    <li>
                                        Account Suspension – We may suspend or
                                        terminate accounts that violate these
                                        terms.
                                    </li>
                                    <li>
                                        Updates – We may modify these terms.
                                        Continued use means acceptance of any
                                        updates.
                                    </li>
                                    <li>
                                        By clicking “Agree,” you confirm that
                                        you understand and accept these terms.
                                    </li>
                                </ol>
                            </div>
                            <div className='flex gap-2'>
                                <input
                                    type='checkbox'
                                    onChange={handleAcceptCondition}
                                />
                                <label>
                                    I have read and agree to the terms and
                                    conditions
                                </label>
                            </div>
                            <div className='flex gap-2 justify-center'>
                                <button
                                    className='bg-green-400 button'
                                    disabled={!enableButton}
                                    onClick={() => {
                                        onboardUser()
                                    }}>
                                    Agree
                                </button>
                            </div>
                        </div>
                    </div>
                </Slide>
            </Modal>
            <ThemeProvider theme={theme}>
                <Grid container component='main' sx={{ height: "100vh" }}>
                    <CssBaseline />

                    <Grid
                        justifyContent={"flex-end"}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                            backgroundImage: `url(${Background})`,
                            backgroundRepeat: "no-repeat",
                            backgroundColor: "black",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            textAlign: "center"
                        }}>
                        <Box
                            className='login_box'
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "end",
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                pr: "5%"
                            }}>
                            <Box sx={{ mt: 10 }}>
                                <div className='login__container'>
                                    <Typography
                                        className='mb-2'
                                        component='h4'
                                        variant='h5'
                                        style={{ color: "#858585" }}>
                                        Sign in
                                    </Typography>
                                    <input
                                        type='text'
                                        className='login__textBox'
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        placeholder='E-mail Address'
                                    />
                                    <input
                                        type='password'
                                        className='login__textBox'
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        placeholder='Password'
                                    />
                                    <button
                                        className='button bg-green-400'
                                        onClick={() =>
                                            logInWithEmailAndPassword(
                                                email,
                                                password
                                            )
                                        }>
                                        Login
                                    </button>
                                    <div>
                                        <Link to='/reset'>Forgot Password</Link>
                                    </div>

                                    <div>
                                        New User?{" "}
                                        <Link to='/register'>Sign Up Here</Link>
                                    </div>
                                    <div>
                                        <hr className='inline-hr' />
                                        <span
                                            style={{
                                                fontSize: 15,
                                                fontWeight: 300,
                                                marginTop: 10,
                                                color: "#858585"
                                            }}>
                                            or login with
                                        </span>
                                        <hr className='inline-hr' />
                                    </div>
                                    <div
                                        style={{
                                            width: "100%",
                                            textAlign: "center"
                                        }}>
                                        <Button
                                            onClick={signInWithGoogle}
                                            variant='contained'
                                            sx={{
                                                borderRadius: "50%",
                                                minWidth: 0,
                                                p: 1,
                                                m: 1,
                                                boxShadow:
                                                    "0px 1px 5px rgba(0, 0, 0, 0.2)",
                                                backgroundColor: "white",
                                                width: 50,
                                                height: 50
                                            }}>
                                            <GoogleLogo
                                                src={googleLogo}
                                                alt='Google'
                                            />
                                        </Button>
                                        <p className='text-red-500 font-semibold'>
                                            {error}
                                        </p>
                                    </div>
                                    <Copyright sx={{ mt: 2 }} />
                                </div>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    )
}
export default Login
