import api from "../components/api_ui"
import socialBuzzJSON from "../components/static/data/social_buzz.json"
import sentimentJSON from "../components/static/data/sentiment.json"
import socialBuzzChangeJSON from "../components/static/data/social_buzz_change.json"
import sentimentChangeJSON from "../components/static/data/sentiment_change.json"
import wordCloudJSON from "../components/static/data/wordcloud.json"
import onMarketStatusSocialBuzzJSON from "../components/static/data/on_market_status_social_buzz.json"
import onMarketStatusSentimentJSON from "../components/static/data/on_market_status_sentiment.json"
import onMarketStatusSocialBuzzChangeJSON from "../components/static/data/on_market_status_social_buzz_change.json"
import onMarketStatusSentimentChangeJSON from "../components/static/data/on_market_status_sentiment_change.json"
import offMarketStatusSocialBuzzJSON from "../components/static/data/off_market_status_social_buzz.json"
import offMarketStatusSentimentJSON from "../components/static/data/off_market_status_sentiment.json"
import offMarketStatusSocialBuzzChangeJSON from "../components/static/data/off_market_status_social_buzz_change.json"
import offMarketStatusSentimentChangeJSON from "../components/static/data/off_market_status_sentiment_change.json"
import alertsJSON from "../components/static/data/alerts.json"

import pako from "pako"
import moment from "moment-timezone"

const getGraphData = async ({
    graph_type,
    date,
    ticker,
    marketStatusDate,
    marketStatusKey
}) => {
    let response

    // If Market Status with Date
    if (
        date &&
        marketStatusDate &&
        (marketStatusKey === "on" || marketStatusKey === "off")
    ) {
        response = await api.get_market_status_graphs({
            graph_type,
            date,
            marketStatusDate,
            market: marketStatusKey
        })

        try {
            const jsonContent = response.replace(/null/g, "0")

            response = JSON.parse(jsonContent).data
        } catch (e) {
            response = response.data
        }

        return response
    }

    // If Date exists
    else if (date) {
        response = await api.get_graphs(graph_type, date, ticker)

        try {
            if (graph_type === "wordcloud") {
                let wordCloud = {}
                wordCloud[ticker] = response

                return wordCloud
            } else {
                const jsonContent = response.replace(/null/g, "0")

                response = JSON.parse(jsonContent).data
            }
        } catch (e) {
            response = response.data
        }

        return response
    }

    // If Market Status exist
    else if (marketStatusKey) {
        if (marketStatusKey === "on") {
            if (graph_type === "social_buzz")
                return onMarketStatusSocialBuzzJSON
            else if (graph_type === "sentiment")
                return onMarketStatusSentimentJSON
            else if (graph_type === "social_buzz_change")
                return onMarketStatusSocialBuzzChangeJSON
            else if (graph_type === "sentiment_change")
                return onMarketStatusSentimentChangeJSON
        } else if (marketStatusKey === "off") {
            if (graph_type === "social_buzz")
                return offMarketStatusSocialBuzzJSON
            else if (graph_type === "sentiment")
                return offMarketStatusSentimentJSON
            else if (graph_type === "social_buzz_change")
                return offMarketStatusSocialBuzzChangeJSON
            else if (graph_type === "sentiment_change")
                return offMarketStatusSentimentChangeJSON
        }
    }

    if (graph_type === "social_buzz") return socialBuzzJSON
    else if (graph_type === "sentiment") return sentimentJSON
    else if (graph_type === "social_buzz_change") return socialBuzzChangeJSON
    else if (graph_type === "sentiment_change") return sentimentChangeJSON
    else if (graph_type === "wordcloud") return wordCloudJSON
}

const decompressResponse = async (response) => {
    try {
        // Step 1: Base64 decode the gzipped data
        const decodedData = atob(response)

        // Step 2: Convert the Base64 string into a Uint8Array
        const charData = decodedData.split("").map((c) => c.charCodeAt(0))
        const byteArray = new Uint8Array(charData)

        // Step 3: Decompress the gzipped data using pako
        const decompressedData = pako.inflate(byteArray, { to: "string" })

        // Step 4: Parse the decompressed JSON data
        const jsonData = JSON.parse(decompressedData.replaceAll("NaN", null))

        return jsonData
    } catch (error) {
        console.log("Decompression Error: ", error)
    }
}

const getTodayDate = (providedDate) => {
    let date = moment().tz("America/New_York")

    if (providedDate) {
        date = moment(providedDate, "YYYY-MM-DD").tz("America/New_York")
    }

    const cutoffTime = date.set({
        hour: 8,
        minute: 30,
        second: 0,
        millisecond: 0
    })

    let assignedDate

    if (date.isBefore(cutoffTime)) {
        // If the current time is before 8:30 AM ET, use the previous day
        assignedDate = date.subtract(1, "day").clone()
    } else {
        // Otherwise, use the current day
        assignedDate = date.clone()
    }

    return assignedDate.format("YYYY-MM-DDTHH:mm:ssZ")
}

const generateAlerts = async (date, price_enabled) => {
    const payloadDate = getTodayDate(date)

    if (!date && price_enabled) {
        const { preMarketAlerts, momentumAlerts } = alertsJSON

        return {
            preMarketAlerts,
            momentumAlerts
        }
    }

    try {
        console.time("API call time")

        const pages = [1, 2, 3]

        // Fetch data from all pages concurrently
        const results = await Promise.all(
            pages.map((page) =>
                api.get_alerts(payloadDate, price_enabled, page)
            )
        )
        console.log("Results", results)

        // Combine the results
        const pre_market_alerts = results.flatMap(
            (result) => result.pre_market_alerts
        )
        const momentum_alerts = results.flatMap(
            (result) => result.momentum_alerts
        )
        console.timeEnd("API call time")

        return {
            preMarketAlerts: pre_market_alerts,
            momentumAlerts: momentum_alerts
        }
    } catch (e) {
        console.log("Error in generateAlerts", e)
    }
}

export { getGraphData, decompressResponse, getTodayDate, generateAlerts }
