const alertConfig = {
    STRONG_BUY: {
        color: "bg-green-500",
        label: "Strong Buy"
    },
    BUY: {
        color: "bg-green-700",
        label: "Buy"
    },
    SELL: {
        color: "bg-red-500",
        label: "Sell"
    },
    STRONG_SELL: {
        color: "bg-red-700",
        label: "Strong Sell"
    },
    params: [
        {
            label: "Pre-market",
            color: "bg-orange-500"
        },
        {
            label: "Momentum",
            color: "bg-blue-500"
        }
    ]
}

const marketStatusItems = [
    {
        label: "All",
        value: "all"
    },
    {
        label: "Market",
        value: "on"
    },
    {
        label: "Off Market",
        value: "off"
    }
]

const alertTypes = [
    {
        label: "Price",
        value: "PRICE"
    },
    {
        label: "Sentiment",
        value: "SENTIMENT"
    }
]

export { alertConfig, marketStatusItems, alertTypes }
