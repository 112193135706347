import MuiQueryBuilder from "./mui-querybuilder/src"
import {} from "@material-ui/core"
import "bootstrap/dist/css/bootstrap.min.css"
import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    IconButton,
    Collapse,
    FormControlLabel,
    Switch,
    Modal,
    Backdrop,
    Snackbar,
    SnackbarContent,
    Tab,
    Tabs,
    Typography,
    Button,
    Grid,
    TextField
} from "@material-ui/core"
import api from "./api_ui"
import LoadingOverlay from "react-loading-overlay"
import React, { useState, useEffect, useContext } from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import DeleteIcon from "@material-ui/icons/Delete"
import axios from "axios"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import InputAdornment from "@mui/material/InputAdornment"
import DQHomepage from "./DQHomepage"
import DQNewsHomepage from "./DQNewsHomepage"
import newsWeights from "./static/news_dq.json"
import GraphLoading from "./static/graph_loading.gif"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "./firebase"
import DQRedditHomepage from "./DQRedditHomepage"
import { WeightContext } from "../context/WeightProvider"

const fields_params_dummy = [
    {
        options: [
            {
                label: "Author",
                value: "Author",
                type: "select",
                options: []
            },
            {
                label: "Person",
                value: "Person",
                type: "select",
                options: []
            },
            {
                label: "Company",
                value: "Company",
                type: "select",
                options: []
            },
            {
                label: "Keyword",
                value: "Keyword",
                type: "select",
                options: []
            }
        ]
    }
]

const useStyles = makeStyles((theme) => ({
    track: {
        color: "green !important",
        backgroundColor: "white !important"
    },
    thumb: {
        height: "100%"
    },
    modal: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    searchRoot: {
        color: "whitesmoke",
        borderBottom: "solid 1px white"
    },
    instructions: {
        color: "#ffffff",
        fontSize: 12,
        textAlign: "center",
        padding: 0,
        lineHeight: 1.2,
        fontWeight: 600,
        marginBottom: 5
    }
}))

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#034ea2"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#034ea2"
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "red"
            },
            "&:hover fieldset": {
                borderColor: "yellow"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#034ea2"
            }
        }
    }
})(TextField)

const weights = {
    Credibility: {
        User_classification: 0.3,
        User_premium_room: 0.12,
        User_plus_tier: 0.05,
        User_following: 0,
        User_official: 0.08,
        User_follower: 0.35,
        joining_date: 0.1
    },
    Usefulness: {
        ST_Sentiment: 0.2,
        likes: 0.8
    },
    Completeness: {
        Body_20: 0.4,
        Body_140: 0.75,
        Body_1000: 1
    }
}
const redditWeights = {
    Credibility: {
        authorPatreonFlair: 0,
        // distinguished:0.142, // 10% of the 70% is 14.2%
        // authorPremium:0.4286, //30% of 70% is 42.86
        // Karma:0.4286
        distinguished: 0.2, // 10% of the 70% is 14.2%
        authorPremium: 0.4, //30% of 70% is 42.86
        karma: 0.4
    },
    Usefulness: {
        score: 0.5, //50% of 30%
        stickied: 0.35,
        totalAwardsReceived: 0.15 //
    }
}
const esgParams = [
    {
        // label: 'Fields',
        options: [
            {
                label: "Environment",
                value: "Environment",
                type: "select",
                options: [
                    "Biofuel",
                    "Carbon footprint",
                    "Carbon offset",
                    "Climate change",
                    "Carbon sequestration",
                    "Circular economy",
                    "Greenwashing",
                    "Net zero",
                    "Renewable energy",
                    "Zero Waste",
                    "GHG",
                    "Air quality",
                    "Water quality"
                ].map((val) => ({
                    label: val,
                    value: val
                }))
            },
            {
                label: "Social",
                value: "Social",
                type: "select",
                options: [
                    "Child Labour ",
                    "Gender Diversity",
                    "Equal Opportunity",
                    "Human Rights",
                    "Working Condition",
                    "Work Life Balance"
                ].map((val) => ({
                    label: val,
                    value: val
                }))
            },
            {
                label: "Governance",
                value: "Governance",
                type: "select",
                options: [
                    "Accounting ",
                    "Anti Competition ",
                    "Business Ethics ",
                    "Critical countries",
                    "Customer health and Safety ",
                    "Insider trading ",
                    "Privacy ",
                    "Tax Fraud "
                ].map((val) => ({
                    label: val,
                    value: val
                }))
            },
            {
                label: "ESG",
                value: "ESG",
                type: "select",
                options: [
                    "Biofuel",
                    "Carbon footprint",
                    "Carbon offset",
                    "Climate change",
                    "Carbon sequestration",
                    "Circular economy",
                    "Greenwashing",
                    "Net zero",
                    "Renewable energy",
                    "Zero Waste",
                    "GHG",
                    "Air quality",
                    "Child Labour ",
                    "Gender Diversity",
                    "Equal Opportunity",
                    "Human Rights",
                    "Working Condition",
                    "Work Life Balance",
                    "Water quality",
                    "Accounting ",
                    "Anti Competition ",
                    "Business Ethics ",
                    "Critical countries",
                    "Customer health and Safety ",
                    "Insider trading ",
                    "Privacy ",
                    "Tax Fraud "
                ].map((val) => ({
                    label: val,
                    value: val
                }))
            }
        ]
    }
]

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    }
}

const QueryBuilder = (props) => {
    const classes = useStyles()
    const emptyRule = function () {
        return {
            field: null,
            id: Math.random(),
            operator: null,
            value: null
        }
    }
    const [extQuery, setExt] = useState(false)
    const [user, loading, error] = useAuthState(auth)
    const { weights } = useContext(WeightContext)

    const emptyGroup = function () {
        return {
            combinator: "or",
            id: Math.random(),
            rules: [emptyRule()]
        }
    }
    let initRules = emptyGroup()
    let finalQuery = {
        ticker: "",
        sdate: "",
        edate: "",
        rules: initRules
    }
    const [query, setQuery] = useState(finalQuery)
    const [ticker_options, setTickerOptions] = useState(api.get_tickers())
    // const [field_options, setFieldOptions] = useState({
    //     Author: [],
    //     Person: [],
    //     Company: [],
    //     Keyword: []
    // })
    const [overlayLoader, handleLoader] = useState(false)
    const [params, setParams] = useState(fields_params_dummy)
    const [fieldLoader, setFieldLoader] = useState(false)
    // let ESData = {
    //     pieChartsData: {},
    //     newsTableData: {},
    //     stocktwitsTableData: {},
    //     hicData: {}
    // }
    const [filters, setFilter] = useState(fields_params_dummy)
    const [modalOpen, setModal] = useState(false)
    const [delModalOpen, setDelModal] = useState(false)
    const [DQModalOpen, setDQModal] = useState(false)

    const [iscollapsed, setIsCollapsed] = React.useState(false)
    const [typeToggle, handleToggle] = useState(true)
    const [DQToggle, handleDQToggle] = useState(false)

    const [alertState, setAlertState] = React.useState({
        open: false,
        vertical: "top",
        horizontal: "center",
        severity: "",
        message: ""
    })
    const [queryVersionState, setVersion] = useState(null)
    const [queryModify, setModifier] = useState(null)
    const [queryId, setQueryId] = useState("")
    const [queryName, setName] = useState("")
    const [querySector, setSector] = useState("")
    // const [savedQuery, setSavedState] = useState(false)
    const [modalText, setModalText] = useState(false)
    const [nlpText, setNLPText] = useState("")
    const [finalWeights, setFinalWeights] = useState(weights)
    const [finalNewsWeights, setFinalNewsWeights] = useState(newsWeights)
    const [finalRedditWeights, setFinalRedditWeights] = useState(redditWeights)
    const [tabValue, setTabValue] = React.useState(0)
    let queryVersion = queryVersionState === null ? 1 : queryVersionState

    const { vertical, horizontal, alertOpen } = alertState
    const handleModalOpen = () => {
        if (!query.ticker || !query.sdate || !query.edate) {
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "No query to Save",
                contentStyle: {
                    backgroundColor: "#008080",
                    fontSize: 18,
                    marginTop: "20%"
                }
            })

            return
        }

        setModal(true)
    }

    const handleModalClose = () => {
        setModal(false)
    }

    const handleDelModalOpen = () => {
        if (!queryName) {
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "No query to Delete",
                contentStyle: {
                    backgroundColor: "#008080",
                    fontSize: 18,
                    marginTop: "20%"
                }
            })

            return
        }

        setDelModal(true)
    }

    const handleDelModalClose = () => {
        setDelModal(false)
    }

    const handleAlertOpen = (newState) => {
        setAlertState({ alertOpen: true, ...newState })
    }

    const handleAlertClose = () => {
        setAlertState({ ...alertState, alertOpen: false })
    }

    const handleSearchBar = (event) => {
        event.persist()
        setNLPText(() => {
            return event.target.value
        })
    }
    const loadParams = async (tempQuery) => {
        setQuery(tempQuery)

        if (tempQuery.ticker && tempQuery.sdate && tempQuery.edate) {
            const data = await api.get_options(
                tempQuery.ticker,
                tempQuery.sdate,
                tempQuery.edate,
                typeToggle ? "text" : "title"
            )

            // setFieldOptions({
            //     Author: data["Author"],
            //     Person: data["Person"],
            //     Keyword: data["Keyword"],
            //     Concept: data["Concept"],
            //     Company: data["Company"]
            // })

            setQuery((prevState) => {
                return tempQuery
            })

            const temp =
                props.parameterFlag === "general"
                    ? [
                          {
                              options: [
                                  {
                                      label: "Author",
                                      type: "select",
                                      value: "Author",
                                      options: data["Author"]
                                  },
                                  {
                                      label: "Person",
                                      type: "select",
                                      value: "Person",
                                      options: data["Person"]
                                  },
                                  {
                                      label: "Company",
                                      type: "select",
                                      value: "Company",
                                      options: data["Company"]
                                  },
                                  {
                                      label: "Keyword",
                                      type: "select",
                                      value: "Keyword",
                                      options: data["Keyword"]
                                  }
                              ]
                          }
                      ]
                    : props.parameterFlag === "esg"
                    ? esgParams
                    : [
                          {
                              options: [
                                  {
                                      label: "Author",
                                      type: "select",
                                      value: "Author",
                                      options: data["Author"]
                                  },
                                  {
                                      label: "Person",
                                      type: "select",
                                      value: "Person",
                                      options: data["Person"]
                                  },
                                  {
                                      label: "Company",
                                      type: "select",
                                      value: "Company",
                                      options: data["Company"]
                                  },
                                  {
                                      label: "Keyword",
                                      type: "select",
                                      value: "Keyword",
                                      options: data["Keyword"]
                                  },
                                  {
                                      label: "Environment",
                                      value: "Environment",
                                      type: "select",
                                      options: [
                                          "Biofuel",
                                          "Carbon footprint",
                                          "Carbon offset",
                                          "Climate change",
                                          "Carbon sequestration",
                                          "Circular economy",
                                          "Greenwashing",
                                          "Net zero",
                                          "Renewable energy",
                                          "Zero Waste",
                                          "GHG",
                                          "Air quality",
                                          "Water quality"
                                      ].map((val) => ({
                                          label: val,
                                          value: val
                                      }))
                                  },
                                  {
                                      label: "Social",
                                      value: "Social",
                                      type: "select",
                                      options: [
                                          "Child Labour ",
                                          "Gender Diversity",
                                          "Equal Opportunity",
                                          "Human Rights",
                                          "Working Condition",
                                          "Work Life Balance"
                                      ].map((val) => ({
                                          label: val,
                                          value: val
                                      }))
                                  },
                                  {
                                      label: "Governance",
                                      value: "Governance",
                                      type: "select",
                                      options: [
                                          "Accounting ",
                                          "Anti Competition ",
                                          "Business Ethics ",
                                          "Critical countries",
                                          "Customer health and Safety ",
                                          "Insider trading ",
                                          "Privacy ",
                                          "Tax Fraud "
                                      ].map((val) => ({
                                          label: val,
                                          value: val
                                      }))
                                  },
                                  {
                                      label: "ESG",
                                      value: "ESG",
                                      type: "select",
                                      options: [
                                          "Biofuel",
                                          "Carbon footprint",
                                          "Carbon offset",
                                          "Climate change",
                                          "Carbon sequestration",
                                          "Circular economy",
                                          "Greenwashing",
                                          "Net zero",
                                          "Renewable energy",
                                          "Zero Waste",
                                          "GHG",
                                          "Air quality",
                                          "Child Labour ",
                                          "Gender Diversity",
                                          "Equal Opportunity",
                                          "Human Rights",
                                          "Working Condition",
                                          "Work Life Balance",
                                          "Water quality",
                                          "Accounting ",
                                          "Anti Competition ",
                                          "Business Ethics ",
                                          "Critical countries",
                                          "Customer health and Safety ",
                                          "Insider trading ",
                                          "Privacy ",
                                          "Tax Fraud "
                                      ].map((val) => ({
                                          label: val,
                                          value: val
                                      }))
                                  }
                              ]
                          }
                      ]
            setParams(temp)
            setFieldLoader(true)
        }
    }

    useEffect(() => {
        if (props.selectedPortfolio) {
            const tickers = JSON.parse(props.selectedPortfolio.stocks)
            setTickerOptions(tickers)
        }
    }, [props.selectedPortfolio])

    useEffect(() => {
        console.log(finalWeights, "in final weights")
    }, [finalWeights, finalNewsWeights])

    const tickerChange = async (event) => {
        try {
            const queryval = {
                ticker: event.target.value,
                sdate: query.sdate,
                edate: query.edate,
                rules: query.rules
            }
            setQuery((t) => {
                return queryval
            })
            await loadParams(queryval)
            props.ticker(queryval.ticker)
        } catch (error) {
            console.error("Error in fetchData:", error)
            // Set state variable to indicate error occurred
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Unable to select Ticker",
                contentStyle: {
                    backgroundColor: "#D81159",
                    fontSize: 18,
                    fonsWeight: 400,
                    marginTop: "20%"
                }
            })
        }
    }

    const startDateChange = async (event) => {
        try {
            const queryval = {
                ticker: query.ticker,
                sdate: event.target.value,
                edate: query.edate,
                rules: query.rules
            }

            setQuery(queryval)

            await loadParams(queryval)
        } catch (error) {
            console.error("Error in fetchData:", error)
            // Set state variable to indicate error occurred
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "unable to select Start Date",
                contentStyle: {
                    backgroundColor: "#D81159",
                    fontSize: 18,
                    fonsWeight: 400,
                    marginTop: "20%"
                }
            })
        }
    }

    const endDateChange = async (event) => {
        try {
            const queryval = {
                ticker: query.ticker,
                sdate: query.sdate,
                edate: event.target.value,
                rules: query.rules
            }

            setQuery(queryval)

            await loadParams(queryval)
        } catch (error) {
            console.error("Error in fetchData:", error)
            // Set state variable to indicate error occurred
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Unable to select End Date",
                contentStyle: {
                    backgroundColor: "#D81159",
                    fontSize: 18,
                    fonsWeight: 400,
                    marginTop: "20%"
                }
            })
        }
    }

    const makeNLPQuery = async () => {
        try {
            let temp = query.rules
            let temp2 = {
                ticker: query.ticker,
                sdate: query.sdate,
                edate: query.edate,
                rules: {
                    combinator: "or",
                    id: Math.random(),
                    rules: [
                        {
                            field: "Body",
                            value: nlpText,
                            operator: "="
                        }
                    ],
                    not: false
                }
            }
            return temp2
        } catch (error) {
            console.error("Error in fetchData:", error)
            // Set state variable to indicate error occurred
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Retry making text search",
                contentStyle: {
                    backgroundColor: "#D81159",
                    fontSize: 18,
                    fonsWeight: 400,
                    marginTop: "20%"
                }
            })
            return null
        }
    }

    useEffect(() => {
        if (fieldLoader === true) {
            setFieldLoader(false)
            setFilter(params)
        }

        if (
            !(
                query.ticker === "" &&
                query.sdate === "" &&
                query.edate === ""
            ) &&
            extQuery
        ) {
            let tempRules
            // setModalText(true)
            try {
                tempRules = !props.queryRules.rules
                    ? emptyGroup()
                    : JSON.parse(props.queryRules.rules.replace(/'/g, '"'))
            } catch (err) {
                console.error("Error parsing rules:", err)
                tempRules = emptyGroup()
            }
            initRules = tempRules
            setQuery((prevState) => {
                return { ...prevState, rules: initRules }
            })
            setExt((prevState) => {
                return false
            })
            setQuery((prevState) => {
                return { ...prevState, rules: initRules }
            })
        }
    }, [fieldLoader])

    useEffect(() => {
        if (
            props.Run &&
            query.rules &&
            query.rules.length !== 0 &&
            filters !== fields_params_dummy
        ) {
            submitQuery()
        }
    }, [query.rules, filters])

    useEffect(() => {
        try {
            if (props.newFlag) {
                setVersion(1)
                setName("")
                setSector("")
                // setSavedState(false)
                setModalText(false)
                setQuery((prevState) => {
                    return {
                        ...prevState,
                        rules: emptyGroup()
                    }
                })
                setFilter(fields_params_dummy)
                setQuery((prevState) => {
                    return {
                        ...prevState,
                        ticker: null,
                        sdate: "",
                        edate: ""
                    }
                })
                setQuery((prevState) => {
                    return {
                        ...prevState,
                        rules: emptyGroup()
                    }
                })

                props.setNewFlag(false)
            }
        } catch (error) {
            console.error("Error in useEffect:", error)
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Error Updating",
                contentStyle: {
                    backgroundColor: "#D81159",
                    fontSize: 18,
                    fonsWeight: 400,
                    marginTop: "20%"
                }
            })
            // Handle error here, such as showing an error message to the user
            // or performing other necessary actions.
        }
    }, [props.newFlag])

    const fetchData = async (finalQuery) => {
        try {
            if (typeof finalQuery.rules === "string") {
                finalQuery.rules = await JSON.parse(finalQuery.rules)
                finalQuery.rules["not"] = false
            }
            try {
                finalQuery.rules["not"] = false
            } catch (err) {}
            setQuery((prevState) => {
                return finalQuery
            })

            const pieChartsData = api
                .get_piecharts_data(
                    finalQuery,
                    DQToggle,
                    finalWeights,
                    finalNewsWeights,
                    finalRedditWeights,
                    weights.edgar
                )
                .then((data) => {
                    if (data) {
                        props.pieChartsData(data)
                    } else {
                        handleAlertOpen({
                            vertical: "top",
                            horizontal: "center",
                            message: "Pie charts data not available",
                            contentStyle: {
                                backgroundColor: "#008080",
                                fontSize: 18,
                                marginTop: "20%"
                            }
                        })
                    }
                    return data
                })

            const isEdgarActive = weights.edgar
            let newsTableData = [],
                stocktwitsTableData = [],
                redditTableData = [],
                blueskyTableData = [],
                edgarTableData = [],
                avHicData = [],
                ibmNewsHicData = [],
                stocktwitsHicData = [],
                redditHicData = [],
                blueskyHicData = [],
                edgarHicData = []

            // 1. Tables
            avHicData = api.get_av_hic_data(finalQuery).then((data) => {
                if (data) {
                    props.avHicData(data)
                } else {
                    handleAlertOpen({
                        vertical: "top",
                        horizontal: "center",
                        message: "Stock Price data not available",
                        contentStyle: {
                            backgroundColor: "#008080",
                            fontSize: 18,
                            marginTop: "20%"
                        }
                    })
                    props.avHicData([])
                }
                return data
            })

            if (!isEdgarActive) {
                props.edgarHicData([])
                props.edgarTableData([])

                newsTableData = api
                    .get_dashtable_data(
                        finalQuery,
                        "ibm_news",
                        DQToggle,
                        finalWeights,
                        finalNewsWeights,
                        finalRedditWeights
                    )
                    .then((data) => {
                        if (data) {
                            if (typeof data === "string") {
                                let temp = JSON.parse(
                                    data.replaceAll("NaN", null)
                                )
                                props.newsTableData(temp)
                            } else {
                                props.newsTableData(data)
                            }
                        } else {
                            handleAlertOpen({
                                vertical: "top",
                                horizontal: "center",
                                message: "News Table data not available",
                                contentStyle: {
                                    backgroundColor: "#008080",
                                    fontSize: 18,
                                    marginTop: "20%"
                                }
                            })
                            props.newsTableData([])
                        }

                        return data
                    })

                stocktwitsTableData = api
                    .get_dashtable_data(
                        finalQuery,
                        "stocktwits",
                        DQToggle,
                        finalWeights,
                        finalNewsWeights,
                        finalRedditWeights
                    )
                    .then((data) => {
                        if (data) {
                            if (typeof data === "string") {
                                let temp = JSON.parse(
                                    data.replaceAll("NaN", null)
                                )
                                props.stocktwitsTableData(temp)
                            } else {
                                props.stocktwitsTableData(data)
                            }
                        } else {
                            props.stocktwitsTableData([])
                        }
                        return data
                    })

                redditTableData = api
                    .get_dashtable_data(
                        finalQuery,
                        "reddit",
                        DQToggle,
                        finalWeights,
                        finalNewsWeights,
                        finalRedditWeights
                    )
                    .then((data) => {
                        if (data) {
                            if (typeof data === "string") {
                                let temp = JSON.parse(
                                    data.replace(" NaN", null)
                                )
                                props.redditTableData(temp)
                            } else {
                                props.redditTableData(data)
                            }
                        } else {
                            handleAlertOpen({
                                vertical: "top",
                                horizontal: "center",
                                message: "Reddit Table data not available",
                                contentStyle: {
                                    backgroundColor: "#008080",
                                    fontSize: 18,
                                    marginTop: "20%"
                                }
                            })
                            props.redditTableData([])
                        }

                        return data ? data : []
                    })

                // Implement Bluesky Dash Table
                blueskyTableData = api
                    .get_dashtable_data(
                        finalQuery,
                        "bluesky",
                        DQToggle,
                        finalWeights,
                        finalNewsWeights,
                        finalRedditWeights
                    )
                    .then((data) => {
                        if (data) {
                            if (typeof data === "string") {
                                let temp = JSON.parse(
                                    data.replace(" NaN", null)
                                )
                                props.blueskyTableData(temp)
                            } else {
                                props.blueskyTableData(data)
                            }
                        } else {
                            handleAlertOpen({
                                vertical: "top",
                                horizontal: "center",
                                message: "Bluesky Table data not available",
                                contentStyle: {
                                    backgroundColor: "#008080",
                                    fontSize: 18,
                                    marginTop: "20%"
                                }
                            })
                            props.blueskyTableData([])
                        }

                        return data ? data : []
                    })

                // 2. Highcharts
                ibmNewsHicData = api
                    .get_ibm_news_data(finalQuery, DQToggle, finalNewsWeights)
                    .then((data) => {
                        if (data) {
                            props.ibmNewsHicData(data)
                        } else {
                            handleAlertOpen({
                                vertical: "top",
                                horizontal: "center",
                                message: "News High Charts data not available",
                                contentStyle: {
                                    backgroundColor: "#008080",
                                    fontSize: 18,
                                    marginTop: "20%"
                                }
                            })
                            props.ibmNewsHicData([])
                        }
                        return data ? data : []
                    })

                stocktwitsHicData = api
                    .get_stocktwits_data(finalQuery, DQToggle, finalWeights)
                    .then((data) => {
                        if (data) {
                            props.stocktwitsHicData(data)
                        } else {
                            handleAlertOpen({
                                vertical: "top",
                                horizontal: "center",
                                message:
                                    "Stocktwits High charts data not available",
                                contentStyle: {
                                    backgroundColor: "#008080",
                                    fontSize: 18,
                                    marginTop: "20%"
                                }
                            })
                            props.stocktwitsHicData([])
                        }
                        return data ? data : []
                    })

                redditHicData = api
                    .get_reddit_data(finalQuery, DQToggle, finalRedditWeights)
                    .then((data) => {
                        if (props.redditHicData) {
                            props.redditHicData(data ? data : [])
                        } else {
                            handleAlertOpen({
                                vertical: "top",
                                horizontal: "center",
                                message:
                                    "Reddit High charts data not available",
                                contentStyle: {
                                    backgroundColor: "#f90504"
                                }
                            })
                        }
                        return data
                    })

                blueskyHicData = api
                    .get_bluesky_data(finalQuery, DQToggle)
                    .then((data) => {
                        if (props.blueskyHicData) {
                            props.blueskyHicData(data ? data : [])
                        } else {
                            handleAlertOpen({
                                vertical: "top",
                                horizontal: "center",
                                message:
                                    "Bluesky High charts data not available",
                                contentStyle: {
                                    backgroundColor: "#f90504"
                                }
                            })
                        }
                        return data
                    })
            } else {
                props.newsTableData([])
                props.stocktwitsTableData([])
                props.redditTableData([])
                props.blueskyTableData([])
                props.ibmNewsHicData([])
                props.stocktwitsHicData([])
                props.redditHicData([])
                props.blueskyHicData([])

                // 1. Tables
                edgarTableData = api
                    .get_dashtable_data(
                        finalQuery,
                        "edgar",
                        DQToggle,
                        finalWeights,
                        finalNewsWeights,
                        finalRedditWeights,
                        weights.edgar
                    )
                    .then((data) => {
                        if (data) {
                            if (typeof data === "string") {
                                let temp = JSON.parse(
                                    data.replaceAll("NaN", null)
                                )
                                props.edgarTableData(temp)
                            } else {
                                props.edgarTableData(data)
                            }
                        } else {
                            handleAlertOpen({
                                vertical: "top",
                                horizontal: "center",
                                message: "Edgar Table data not available",
                                contentStyle: {
                                    backgroundColor: "#008080",
                                    fontSize: 18,
                                    marginTop: "20%"
                                }
                            })
                            props.edgarTableData([])
                        }
                        return data
                    })

                edgarHicData = api.get_edgar_data(finalQuery).then((data) => {
                    if (props.edgarHicData) {
                        props.edgarHicData(data ? data : [])
                    } else {
                        handleAlertOpen({
                            vertical: "top",
                            horizontal: "center",
                            message: "Edgar High charts data not available",
                            contentStyle: {
                                backgroundColor: "#f90504"
                            }
                        })
                    }
                    return data
                })
            }

            const compositeSentimentData = api
                .get_composite_sentiment(
                    finalQuery,
                    DQToggle,
                    finalWeights,
                    finalNewsWeights,
                    finalRedditWeights,
                    weights.edgar
                )
                .then((data) => {
                    if (props.compositeSentimentData.length > 0) {
                        props.compositeSentimentData(data)
                    } else {
                        handleAlertOpen({
                            vertical: "top",
                            horizontal: "center",
                            message: "Composite data not available",
                            contentStyle: {
                                backgroundColor: "#008080",
                                fontSize: 18,
                                marginTop: "20%"
                            }
                        })
                        props.compositeSentimentData([])
                    }
                    return data
                })

            const hicData = {
                alphavantage: await avHicData,
                ibm_news: await ibmNewsHicData,
                stocktwits: await stocktwitsHicData,
                reddit: await redditHicData,
                edgar: await edgarHicData,
                bluesky: await blueskyHicData
            }

            if (props.hicData) {
                props.hicData(hicData)
            } else {
                handleAlertOpen({
                    vertical: "top",
                    horizontal: "center",
                    message: "Stock Price data not available",
                    contentStyle: {
                        backgroundColor: "#008080",
                        fontSize: 18,
                        marginTop: "20%"
                    }
                })
            }

            return {
                pieChartsData: await pieChartsData,
                newsTableData,
                stocktwitsTableData,
                hicData,
                compositeSentimentData: await compositeSentimentData,
                redditTableData,
                blueskyTableData
            }
        } catch (error) {
            console.error("Error in fetchData:", error)
            // Set state variable to indicate error occurred
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Unable to get data",
                contentStyle: {
                    backgroundColor: "#D81159",
                    fontSize: 18,
                    fonsWeight: 400,
                    marginTop: "20%"
                }
            })
        }
    }

    const submitQuery = async () => {
        if (
            (query.ticker !== null) &
            (query.sdate.length > 0) &
            (query.edate.length > 0)
        ) {
            console.log("Running Query...", query)
            if (nlpText.length > 0) {
                let tempQuery = await makeNLPQuery()
                handleLoader(true)
                props.querySetter(query)
                var start = window.performance.now()
                const result = await fetchData(tempQuery)
                console.log("WAITING")
                var end = window.performance.now()
                console.log(`Execution time: ${end - start} ms`)
                if (result) result["loading"] = false
                handleLoader(false)
            } else {
                handleLoader(true)

                if (!query.rules || query.rules.length === 0) {
                    if (!props.Run) {
                        if (nlpText.length < 1) {
                            setQuery((prevState) => {
                                return { ...prevState, rules: emptyGroup() }
                            })
                        }
                    }
                }
                props.querySetter(query)
                var start = window.performance.now()
                const result = await fetchData(query)
                var end = window.performance.now()
                console.log(`Execution time: ${end - start} ms`)
                if (result) result["loading"] = false
                handleLoader(false)
            }
        } else {
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Enter ticker start date and end date",
                contentStyle: {
                    backgroundColor: "#D81159",
                    fontSize: 18,
                    fontWeight: 400,
                    marginTop: "20%"
                }
            })
        }
    }

    useEffect(() => {
        if (props.Run) {
            setIsCollapsed(true)

            if (!props.newFlag) {
                if (props.queryRules) {
                    setExt(true)
                }

                try {
                    if (
                        !(
                            props.queryRules.ticker === "" &&
                            props.queryRules.sdate === "" &&
                            props.queryRules.edate === ""
                        )
                    ) {
                        const queryval = {
                            ticker: props.queryRules.ticker,
                            sdate: props.queryRules.sdate,
                            edate: props.queryRules.edate
                        }
                        loadParams(queryval)

                        setVersion(() => {
                            return props.queryRules.query_version + 1
                        })
                    }
                } catch (err) {
                    console.error("Error in useEffect:", err)
                }
            }
        } else {
            setIsCollapsed(false)
        }
    }, [props.Run])

    const saveQuery = async () => {
        try {
            const resp = await axios.post(
                `${process.env.REACT_APP_DATA_SERVER_API_URL}/query/save`,
                {
                    data: {
                        queryName: queryName,
                        queryVersion: queryVersion,
                        prevVersion: queryVersion > 0 ? queryVersion - 1 : 0,
                        querySector: querySector,
                        query: JSON.stringify(query),
                        userId: user.uid
                    }
                },
                { headers: { "Access-Control-Allow-Origin": "*" } }
            )

            // Check if response status is not successful
            if (resp.status !== 200) {
                handleAlertOpen({
                    vertical: "top",
                    horizontal: "center",
                    message: "Error saving query",
                    contentStyle: {
                        backgroundColor: "#D81159",
                        fontSize: 18,
                        fonsWeight: 400,
                        marginTop: "20%"
                    }
                })
            }

            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Query Saved Successfully",
                contentStyle: {
                    backgroundColor: "#008080"
                }
            })

            props.populateOptions()

            setModifier((t) => {
                return 1
            })
            handleModalClose()
            props.setNewFlag((t) => {
                return true
            })
        } catch (error) {
            console.error("Error saving query:", error)
            // Handle error here, such as showing an error message to the user
            // or performing other necessary actions.
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Error saving query",
                contentStyle: {
                    backgroundColor: "#D81159",
                    fontSize: 18,
                    fonsWeight: 400,
                    marginTop: "20%"
                }
            })
        }
    }

    const setSavedQuery = (queryParams) => {
        try {
            // props.setNewFlag((t) => {
            //     return true
            // })
            // setSavedState((t) => {
            //     return true
            // })

            let finalQueryTwo = {
                ticker: "",
                sdate: "",
                edate: "",
                rules: emptyGroup()
            }

            setQuery((prevState) => {
                return {
                    ...prevState,
                    rules: emptyGroup()
                }
            })

            if (queryParams) {
                if (queryParams.query_id) {
                    setQueryId(() => {
                        return queryParams.query_id
                    })
                }

                if (queryParams.query_name) {
                    setName(() => {
                        return queryParams.query_name
                    })
                }

                if (queryParams.query_sector) {
                    setSector(() => {
                        return queryParams.query_sector
                    })
                }

                if (queryParams.query_version) {
                    setVersion(() => {
                        return queryParams.query_version + 1
                    })
                }

                finalQueryTwo.ticker = queryParams.ticker
                finalQueryTwo.sdate = queryParams.sdate
                finalQueryTwo.edate = queryParams.edate
                finalQueryTwo.rules = queryParams.rules

                setQuery((prevState) => {
                    return {
                        ...prevState,
                        rules: { rules: emptyGroup() }
                    }
                })

                setQuery(() => {
                    return finalQueryTwo
                })
            } else {
                setQuery((prevState) => {
                    return {
                        ...prevState,
                        rules: emptyGroup()
                    }
                })
            }
        } catch (err) {
            console.log("error set saved", err)

            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Error setting saved query",
                contentStyle: {
                    backgroundColor: "#D81159",
                    fontSize: 18,
                    fonsWeight: 400,
                    marginTop: "20%"
                }
            })
        }
    }

    const deleteQuery = async () => {
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_DATA_SERVER_API_URL}/query/delete`,
                {
                    query_id: queryId,
                    userId: user.uid
                },
                { headers: { "Access-Control-Allow-Origin": "*" } }
            )

            // Check if response status is not successful
            if (!data.status) {
                handleAlertOpen({
                    vertical: "top",
                    horizontal: "center",
                    message: "Error saving query",
                    contentStyle: {
                        backgroundColor: "#D81159",
                        fontSize: 18,
                        fonsWeight: 400,
                        marginTop: "20%"
                    }
                })
                return
            }

            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Query Deleted Successfully",
                contentStyle: {
                    backgroundColor: "#008080"
                }
            })

            props.populateOptions()

            handleDelModalClose()
            setModifier((t) => {
                return 1
            })
            props.setNewFlag((t) => {
                return true
            })
        } catch (error) {
            console.error("Error deleting query:", error)
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Deleting unknown query",
                contentStyle: {
                    backgroundColor: "#D81159",
                    fontSize: 18,
                    fonsWeight: 400,
                    marginTop: "20%"
                }
            })
        }
    }
    useEffect(() => {
        if (queryModify === 1) {
            setModifier((t) => {
                return 0
            })
            props.setUpdates(true)
        }
    }, [queryModify])

    const handleCollapse = () => {
        setIsCollapsed((prev) => !prev)
    }

    useEffect(() => {
        props.toggle(typeToggle ? "text" : "title")
        props.DQtoggle(DQToggle ? "DQ_sentiment" : "Sentiment_score")

        // 5454?
        loadParams(query)
    }, [typeToggle, DQToggle])

    useEffect(() => {
        if (!props.newFlag) {
            if (props.queryRules) {
                setExt((prevState) => {
                    return true
                })
            }
            setSavedQuery(props.queryRules)
            try {
                if (
                    !(
                        props.queryRules.ticker === "" &&
                        props.queryRules.sdate === "" &&
                        props.queryRules.edate === ""
                    )
                ) {
                    const queryval = {
                        ticker: props.queryRules.ticker,
                        sdate: props.queryRules.sdate,
                        edate: props.queryRules.edate
                    }
                    loadParams(queryval)
                }
            } catch (err) {
                console.log("err", err)
                // handleAlertOpen({
                //     vertical: 'top', horizontal: 'center', message: "Error: Setting query rule", contentStyle: {
                //         backgroundColor: "#D81159", fontSize: 18, fonsWeight:400, marginTop: "20%"
                //     }
                // })
            }
        }
    }, [props.queryRules])

    return (
        <React.Fragment style={{ border: "solid 2px blue" }}>
            <LoadingOverlay
                active={overlayLoader}
                spinner={
                    <img
                        src={GraphLoading}
                        style={{ width: "100%", height: "auto" }}
                    />
                }>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={alertOpen}
                    onClose={handleAlertClose}
                    autoHideDuration={2000}
                    key={vertical + horizontal}
                    severity='info'
                    bodyStyle={{ backgroundColor: "teal", color: "coral" }}>
                    <SnackbarContent
                        message={alertState.message}
                        style={alertState.contentStyle}></SnackbarContent>
                </Snackbar>
                <div>
                    <Modal
                        aria-labelledby='transition-modal-title'
                        aria-describedby='transition-modal-description'
                        className={classes.modal}
                        open={delModalOpen}
                        keepMounted
                        onClose={handleDelModalClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}>
                        <div
                            classes='container-fluid'
                            className={classes.paper}
                            style={{ maxWidth: "70%" }}>
                            <div className='row' style={{ marginBottom: 0 }}>
                                <h2
                                    className='col-12 transition-modal-description'
                                    style={{
                                        textAlign: "center",
                                        fontWeight: 500,
                                        marginTop: 5
                                    }}>
                                    Confirm Delete
                                </h2>
                            </div>
                            <hr></hr>
                            <div
                                className='row justify-content-center'
                                style={{
                                    textAlign: "center",
                                    fontFamily: "Poppins",
                                    fontSize: "1.1rem",
                                    fontWeight: 400
                                }}>
                                <h4 style={{ marginBottom: "4%" }}>
                                    Do you wish to delete
                                </h4>
                                <div className='row justify-content-center'>
                                    <Button
                                        variant='outlined'
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 500,
                                            fontFamily: "Copperplate",
                                            color: "#034ea2"
                                        }}
                                        className='col-md-4'
                                        onClick={deleteQuery}>
                                        Yes
                                    </Button>
                                    <div className='col-md-3'></div>
                                    <Button
                                        variant='outlined'
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 500,
                                            fontFamily: "Copperplate",
                                            color: "#034ea2",
                                            marginRight: "auto"
                                        }}
                                        className='col-md-4'
                                        onClick={() => {
                                            handleDelModalClose()
                                        }}>
                                        No
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>

                <div>
                    <Modal
                        aria-labelledby='transition-modal-title'
                        aria-describedby='transition-modal-description'
                        className={classes.modal}
                        open={DQModalOpen}
                        keepMounted
                        onClose={() => {
                            setDQModal(() => {
                                return false
                            })
                        }}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}>
                        <div
                            classes='container-fluid'
                            className={classes.paper}
                            style={{
                                maxWidth: "70%",
                                overflowY: "scroll",
                                maxHeight: "95%"
                            }}>
                            <Box sx={{ width: "100%" }}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider"
                                    }}>
                                    <Tabs
                                        value={tabValue}
                                        onChange={(event, newValue) => {
                                            setTabValue(newValue)
                                        }}
                                        aria-label='basic tabs example'>
                                        <Tab label='Social Media' />
                                        <Tab label='News' />
                                        <Tab label='Reddit' />
                                    </Tabs>
                                </Box>
                                <TabPanel value={tabValue} index={0}>
                                    <div
                                        className='row'
                                        style={{ marginBottom: 0 }}>
                                        <DQHomepage
                                            setWeights={async (data) => {
                                                setFinalWeights(() => {
                                                    return data
                                                })
                                            }}></DQHomepage>
                                    </div>
                                </TabPanel>
                                <TabPanel value={tabValue} index={1}>
                                    <div
                                        className='row'
                                        style={{ marginBottom: 0 }}>
                                        <DQNewsHomepage
                                            setWeights={async (data) => {
                                                setFinalNewsWeights(() => {
                                                    return data
                                                })
                                            }}></DQNewsHomepage>
                                    </div>
                                </TabPanel>
                                <TabPanel value={tabValue} index={2}>
                                    <div
                                        className='row'
                                        style={{ marginBottom: 0 }}>
                                        <DQRedditHomepage
                                            setWeights={async (data) => {
                                                setFinalRedditWeights(() => {
                                                    return data
                                                })
                                            }}></DQRedditHomepage>
                                    </div>
                                </TabPanel>
                            </Box>
                        </div>
                    </Modal>
                </div>
                <div
                    className='container-fluid w-100 mb-4 border-group'
                    style={{
                        backgroundColor: "white"
                    }}>
                    <div
                        className='row flex items-center justify-content-left py-3'
                        style={{
                            width: "130% !important",
                            borderRadius: 8,
                            backgroundImage:
                                "linear-gradient(to right, #034ea2, #0853a7, #0d57ad, #115cb2, #1561b8, #1561b8, #1561b8, #1561b8, #115cb2, #0d57ad, #0853a7, #034ea2)"
                        }}>
                        <div className='col-md-1'>
                            <FormControl
                                style={{
                                    width: "100%",
                                    backgroundColor: "white",
                                    color: "black",
                                    borderRadius: 10,
                                    paddingTop: 4
                                }}>
                                <InputLabel
                                    id='Ticker-label'
                                    style={{
                                        fontSize: 18,
                                        marginLeft: 10,
                                        marginBottom: 10,
                                        color: "black"
                                    }}>
                                    Ticker
                                </InputLabel>
                                <Select
                                    labelId='ticker-label'
                                    defaultValue={null}
                                    variant='outlined'
                                    onChange={tickerChange}
                                    value={query.ticker}
                                    style={{ width: "100%" }}>
                                    {ticker_options
                                        ? ticker_options.map((ticker, key) => (
                                              <MenuItem
                                                  value={ticker}
                                                  style={{
                                                      fontSize: 15,
                                                      width: "100%"
                                                  }}
                                                  key={key}>
                                                  {ticker}
                                              </MenuItem>
                                          ))
                                        : null}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-auto'>
                            <FormControl
                                style={{
                                    width: "100%",
                                    backgroundColor: "white",
                                    color: "black",
                                    borderRadius: 10,
                                    paddingTop: 4
                                }}>
                                <TextField
                                    onChange={startDateChange}
                                    type='date'
                                    format={"MM/DD/YYYY"}
                                    variant='outlined'
                                    className='ml-2 mr-2'
                                    label='Start Date'
                                    InputLabelProps={{
                                        shrink: true,
                                        style: {
                                            fontSize: 14,
                                            marginTop: 4
                                        }
                                    }}
                                    value={query.sdate}
                                />
                            </FormControl>
                        </div>
                        <div className='col-md-auto '>
                            <FormControl
                                style={{
                                    width: "100%",
                                    backgroundColor: "white",
                                    color: "black",
                                    borderRadius: 10,
                                    paddingTop: 4
                                }}>
                                <TextField
                                    onChange={endDateChange}
                                    type='date'
                                    variant='outlined'
                                    className='ml-2 mr-2'
                                    label='End Date'
                                    value={query.edate}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: {
                                            fontSize: 14,
                                            marginTop: 4
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                        {!weights.edgar && (
                            <div className='col-md-auto flex gap-2'>
                                <div
                                    className='flex flex-col fourth-step justify-content-center'
                                    style={{
                                        backgroundColor: "inherit"
                                    }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            margin: "10 auto",
                                            float: "left",
                                            clear: "left",
                                            marginLeft: 8
                                        }}>
                                        <span
                                            style={{
                                                marginTop: 6,
                                                color: "white"
                                            }}>
                                            Title
                                        </span>
                                        <FormControl
                                            style={{
                                                display: "flex",
                                                backgroundImage:
                                                    "linear-gradient(to right, #1561b8, #1561b8, #1561b8)",
                                                color: "black",
                                                borderRadius: 8
                                            }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={typeToggle}
                                                        onChange={() => {
                                                            handleToggle(
                                                                !typeToggle
                                                            )
                                                        }}
                                                        name='checkedB'
                                                        color='default'
                                                        classes={{
                                                            track: classes.track
                                                        }}
                                                        style={{
                                                            color: "white",
                                                            display: "flex"
                                                        }}
                                                    />
                                                }
                                                style={{
                                                    color: "white",
                                                    display: "flex",
                                                    marginLeft: 2,
                                                    marginRight: 2
                                                }}
                                            />
                                        </FormControl>
                                        <span
                                            style={{
                                                marginTop: 6,
                                                color: "white",
                                                display: "block"
                                            }}>
                                            Text
                                        </span>
                                    </div>
                                    <h6
                                        className='m-0'
                                        style={{
                                            display: "block",
                                            color: "white"
                                        }}>
                                        Sentiment based on
                                    </h6>
                                </div>
                                <div
                                    className=' justify-content-center new-step-five'
                                    style={{
                                        backgroundColor: "inherit",
                                        display: "block",
                                        textAlign: "center",
                                        padding: 0
                                    }}>
                                    <div
                                        style={{
                                            display: "flex"
                                        }}>
                                        <span
                                            style={{
                                                marginTop: 6,
                                                color: "white"
                                            }}>
                                            Non DQ
                                        </span>
                                        <FormControl
                                            style={{
                                                display: "flex",
                                                backgroundImage:
                                                    "linear-gradient(to right, #1561b8, #1561b8, #1561b8)",
                                                color: "black",
                                                borderRadius: 8
                                            }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={DQToggle}
                                                        onChange={() => {
                                                            handleDQToggle(
                                                                !DQToggle
                                                            )
                                                            handleAlertOpen({
                                                                vertical: "top",
                                                                horizontal:
                                                                    "center",
                                                                message:
                                                                    "Re-run Query to see changes",
                                                                contentStyle: {
                                                                    backgroundColor:
                                                                        "#008080",
                                                                    fontSize: 18,
                                                                    marginTop:
                                                                        "10%"
                                                                }
                                                            })
                                                        }}
                                                        name='checkedB'
                                                        color='default'
                                                        classes={{
                                                            track: classes.track
                                                        }}
                                                        style={{
                                                            color: "white",
                                                            display: "flex"
                                                        }}
                                                    />
                                                }
                                                style={{
                                                    color: "white",
                                                    display: "flex",
                                                    marginLeft: 2,
                                                    marginRight: 2
                                                }}
                                            />
                                        </FormControl>
                                        <span
                                            style={{
                                                marginTop: 6,
                                                color: "white",
                                                display: "block"
                                            }}>
                                            DQ
                                        </span>
                                    </div>
                                    <Button
                                        variant='text'
                                        size='small'
                                        onClick={() => {
                                            setDQModal(() => {
                                                return true
                                            })
                                        }}
                                        style={{
                                            display: "block",
                                            color: "white",
                                            textAlign: "center",
                                            width: "100%"
                                        }}>
                                        Sentiment Type
                                    </Button>
                                </div>
                            </div>
                        )}
                        <div className='col-md-auto'>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-end"
                                }}>
                                <TextField
                                    id='nlp-search'
                                    label='Search'
                                    variant='filled'
                                    InputLabelProps={{
                                        style: { color: "black" }
                                    }}
                                    size='medium'
                                    value={nlpText}
                                    onChange={handleSearchBar}
                                    fullWidth
                                    style={{
                                        backgroundColor: "white",
                                        borderRadius: 5
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                {" "}
                                                <SearchOutlinedIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        </div>
                        <div className='col-md-auto fifth-step'>
                            <Button
                                variant='contained'
                                style={{
                                    width: "90%",
                                    backgroundColor: "#00aecb",
                                    color: "white",
                                    height: "70%",
                                    marginBottom: 5,
                                    fontWeight: 400,
                                    fontSize: 20,
                                    borderRadius: 10,
                                    textTransform: "capitalize"
                                }}
                                onClick={() => {
                                    submitQuery()
                                }}>
                                Run
                            </Button>
                        </div>
                        <div className='col-md-auto twelth-step'>
                            <Button
                                variant='contained'
                                style={{
                                    width: "90%",
                                    backgroundColor: "#00aecb",
                                    color: "white",
                                    height: "70%",
                                    marginBottom: 5,
                                    fontWeight: 400,
                                    fontSize: 20,
                                    borderRadius: 10,
                                    textTransform: "capitalize"
                                }}
                                onClick={handleModalOpen}>
                                Save
                            </Button>
                            <div>
                                <Modal
                                    aria-labelledby='transition-modal-title'
                                    aria-describedby='transition-modal-description'
                                    className={classes.modal}
                                    open={modalOpen}
                                    keepMounted
                                    onClose={handleModalClose}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500
                                    }}>
                                    <div
                                        className={classes.paper}
                                        classes='container-fluid w-100 '>
                                        <h2 id='transition-modal-title'>
                                            Confirm Save
                                        </h2>
                                        <div
                                            className='row'
                                            id='transition-modal-description'>
                                            <hr></hr>
                                            <form
                                                className='mb-5 justify-content-center align-items-center'
                                                noValidate
                                                autoComplete='off'>
                                                <CssTextField
                                                    key='dummy'
                                                    label={
                                                        queryName
                                                            ? "   "
                                                            : "Query Name"
                                                    }
                                                    variant='standard'
                                                    className='col-md-9 mr-2'
                                                    disabled={modalText}
                                                    value={queryName}
                                                    onChange={(event) => {
                                                        event.persist()
                                                        setName((t) => {
                                                            return event.target
                                                                .value
                                                        })
                                                    }}
                                                    style={{
                                                        marginRight: "2%"
                                                    }}
                                                />
                                                <CssTextField
                                                    label='Version'
                                                    disabled
                                                    value={
                                                        queryVersion
                                                            ? queryVersion
                                                            : 1
                                                    }
                                                    variant='standard'
                                                    className='col-md-2 ml-2'
                                                    type='number'
                                                />
                                                <br></br>
                                                <CssTextField
                                                    disabled={modalText}
                                                    label={
                                                        querySector
                                                            ? "   "
                                                            : "Query Sector"
                                                    }
                                                    variant='standard'
                                                    value={querySector}
                                                    onChange={(event) => {
                                                        event.persist()
                                                        setSector((t) => {
                                                            return event.target
                                                                .value
                                                        })
                                                    }}
                                                    fullWidth
                                                    className='mt-2 col-10'
                                                />
                                            </form>

                                            <hr></hr>
                                            <div
                                                className='row justify-content-end align-items-right'
                                                style={{
                                                    textAlign:
                                                        "right !important",
                                                    width: "100%",
                                                    height: 50
                                                }}>
                                                <Button
                                                    color='primary'
                                                    variant='contained'
                                                    className='col-1'
                                                    onClick={saveQuery}
                                                    style={{
                                                        backgroundColor:
                                                            "#034ea2",
                                                        color: "white",
                                                        marginRight: "2%",
                                                        float: "right !important"
                                                    }}>
                                                    Save
                                                </Button>
                                                <Button
                                                    color='primary'
                                                    onClick={handleModalClose}
                                                    variant='contained'
                                                    className='col-1'
                                                    style={{
                                                        backgroundColor:
                                                            "#034ea2",
                                                        color: "white",
                                                        padding: "0px 5px",
                                                        float: "right !important"
                                                    }}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        <div className='col-md-1 flex gap-2'>
                            <IconButton
                                className='fourteenth-step'
                                variant='contained'
                                style={{
                                    width: "50%",
                                    backgroundColor: "#00aecb",
                                    color: "white",
                                    height: "70%",
                                    marginBottom: 5,
                                    fontWeight: 400,
                                    fontSize: 20,
                                    borderRadius: 10,
                                    textTransform: "capitalize"
                                }}
                                onClick={handleDelModalOpen}>
                                <DeleteIcon fontSize='medium' />
                            </IconButton>
                            <IconButton
                                className='fourteenth-step'
                                variant='contained'
                                style={{
                                    width: "50%",
                                    backgroundColor: "#00aecb",
                                    color: "white",
                                    height: "70%",
                                    marginBottom: 5,
                                    fontWeight: 400,
                                    fontSize: 20,
                                    borderRadius: 10,
                                    textTransform: "capitalize"
                                }}
                                onClick={() => {
                                    props.setAlertsWeightageModalOpen(true)
                                }}>
                                ..
                            </IconButton>
                        </div>
                        {weights.edgar ? (
                            <div className='col-md-auto'>
                                <h4 className='text-white'>
                                    Edgar Mode Active
                                </h4>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <Collapse in={iscollapsed} timeout='auto'>
                        <div
                            className='row d-flex pt-0 mt-0'
                            style={{ width: "100%", height: "40vh" }}>
                            <div>
                                <Grid
                                    item
                                    style={{
                                        width: "100%",
                                        height: "42vh",
                                        overflowY: "auto"
                                    }}>
                                    <MuiQueryBuilder
                                        // classList
                                        debug
                                        maxLevels={15}
                                        onChange={(queryval, valid) => {
                                            const temp = {
                                                ticker: query.ticker,
                                                sdate: query.sdate,
                                                edate: query.edate,
                                                rules: queryval
                                            }
                                            if (valid == true) {
                                                finalQuery = temp
                                                setQuery((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        rules: queryval
                                                    }
                                                })
                                            }
                                        }}
                                        query={query.rules}
                                        sortFilters={false}
                                        filters={
                                            filters === fields_params_dummy
                                                ? fields_params_dummy
                                                : filters
                                        }
                                        qtwo={query.rules}
                                        new={props.newFlag}
                                        queryVersion={queryVersion}
                                    />
                                </Grid>
                            </div>
                        </div>
                    </Collapse>
                    {!weights.edgar && (
                        <div className='row justify-content-center mt-3 flex'>
                            <IconButton
                                className='col-12 second-step'
                                size='medium'
                                onClick={handleCollapse}
                                aria-label='Person'
                                style={{
                                    fontSize: 30,
                                    marginRight: 15,
                                    borderRadius: 10,
                                    padding: 5,
                                    color: "black"
                                }}>
                                {iscollapsed ? (
                                    <ExpandLessIcon
                                        fontSize='medium'
                                        style={{
                                            transform: "scale(1.5)",
                                            color: "#848482"
                                        }}></ExpandLessIcon>
                                ) : (
                                    <div className='flex items-center'>
                                        <p className='text-base m-0'>
                                            Input Query Panel
                                        </p>
                                        <ExpandMoreIcon
                                            style={{
                                                color: "#848482"
                                            }}></ExpandMoreIcon>
                                    </div>
                                )}
                            </IconButton>
                        </div>
                    )}
                </div>
            </LoadingOverlay>
        </React.Fragment>
    )
}

export default QueryBuilder
